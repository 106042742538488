import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from "./AuthContext";
import SessionBanner from './components/SessionBanner';
import MessageList from './components/MessageList';
import InputArea from './components/InputArea';
import EndSessionModal from './components/EndSessionModal';

const ChatInterface = ({ darkMode }) => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showEndSessionModal, setShowEndSessionModal] = useState(false);
  const [sessionEnded, setSessionEnded] = useState(true);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const scrollAreaRef = useRef(null);
  const messagesEndRef = useRef(null);

  const { user } = useAuth();
  const { sessionId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchExistingMessages = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/chat/session/${sessionId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await user.getIdToken()}`
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch existing messages');
        }

        const data = await response.json();
        setMessages(data.messages.map(msg => ({ ...msg, isNew: false })));
        setSessionEnded(data.ended);
      } catch (error) {
        console.error('Error fetching existing messages:', error);
        alert("Failed to load existing messages. Please try refreshing the page.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchExistingMessages();
  }, [sessionId, user]);

  useEffect(() => {
    if (location.state?.initialMessage && messages.length === 0) {
      setMessages([{ role: 'assistant', content: location.state.initialMessage, isNew: true }]);
      setTimeout(() => {
        setMessages(prevMessages =>
          prevMessages.map(msg => ({ ...msg, isNew: false }))
        );
      }, 300);
    }
  }, [location.state, messages.length]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages, isLoading]);

  const handleEndSession = () => {
    setShowEndSessionModal(true);
  };

  const confirmEndSession = async () => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/chat/session/${sessionId}/end`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await user.getIdToken()}`
        },
      });

    } catch (error) {
      console.error('Error ending session:', error);
    } finally {
      setShowEndSessionModal(false);
      navigate('/dashboard');
    }
  };

  const handleSendMessage = async (inputMessage) => {
    const newMessage = { role: 'user', content: inputMessage, isNew: true };
    setMessages(prevMessages => [...prevMessages, newMessage]);
    setIsLoading(true);
    setIsWaitingForResponse(true);

    setTimeout(() => {
      setMessages(prevMessages =>
        prevMessages.map(msg =>
          msg === newMessage ? { ...msg, isNew: false } : msg
        )
      );
    }, 300);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/chat/session/${sessionId}/interact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await user.getIdToken()}`
        },
        body: JSON.stringify({ 
          userId: user.uid,
          message: inputMessage
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to get response from AI');
      }

      const data = await response.json();
      
      setIsLoading(false);
      const aiMessage = { role: 'assistant', content: data.reply, isNew: true };
      setMessages(prevMessages => [...prevMessages, aiMessage]);

      setTimeout(() => {
        setMessages(prevMessages =>
          prevMessages.map(msg =>
            msg === aiMessage ? { ...msg, isNew: false } : msg
          )
        );
      }, 300);
    } catch (error) {
      console.error('Error sending message:', error);
      setIsLoading(false);
      setMessages(prevMessages => [...prevMessages, {
        role: 'assistant',
        content: "I'm sorry, I'm having trouble responding right now. Please try again later.",
        isNew: true
      }]);
    } finally {
      setIsWaitingForResponse(false);
    }
  };

  return (
    <div className={`flex flex-col h-[calc(100vh-64px)] w-full ${darkMode ? 'bg-zinc-900 text-zinc-100' : 'bg-zinc-50 text-zinc-900'}`}>
      <SessionBanner darkMode={darkMode} onEndSession={handleEndSession} sessionEnded={sessionEnded}/>
      <MessageList 
        messages={messages}
        isLoading={isLoading}
        darkMode={darkMode}
        scrollAreaRef={scrollAreaRef}
        messagesEndRef={messagesEndRef}
      />
      {sessionEnded ? <></> : <InputArea
        darkMode={darkMode}
        onSendMessage={handleSendMessage}
        isWaitingForResponse={isWaitingForResponse}
      />}
      <EndSessionModal
        darkMode={darkMode}
        showModal={showEndSessionModal}
        onClose={() => setShowEndSessionModal(false)}
        onConfirm={confirmEndSession}
      />
    </div>
  );
};

export default ChatInterface;