import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from "./components/ui/Button";

const NotFoundPage = ({ darkMode }) => {
  return (
    <div className={`flex flex-col min-h-screen ${darkMode ? 'bg-zinc-900 text-zinc-100' : 'bg-white text-gray-900'}`}>
      <main className="flex-grow flex flex-col items-center justify-center text-center px-4">
        <h1 className="text-6xl font-bold mb-4">404</h1>
        <p className="text-2xl mb-8 text-gray-600 dark:text-gray-300">
          Oops! It seems you've wandered off the path.
        </p>
        <Link to="/">
          <Button className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-3 px-6 rounded-full text-lg transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">
            Return to Homepage
          </Button>
        </Link>
      </main>
    </div>
  );
};

export default NotFoundPage;