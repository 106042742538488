import React from 'react';
import { Link } from 'react-router-dom';
import { Check, MessageCircle, Zap, Calendar } from 'lucide-react';
import { Button } from "./components/ui/Button";

const PricingTier = ({ name, price, conversationFrequency, features, isRecommended, darkMode }) => (
  <div className={`flex flex-col p-6 mx-auto max-w-lg text-center ${isRecommended ? 'border-2 border-teal-500' : 'border border-gray-200'} rounded-lg shadow ${darkMode ? 'bg-zinc-800 text-white' : 'bg-white text-gray-900'} xl:p-8`}>
    <h3 className="mb-4 text-2xl font-semibold">{name}</h3>
    <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400 mb-4">
      <MessageCircle className="inline-block mr-2" size={20} />
      {conversationFrequency}
    </p>
    <div className="flex justify-center items-baseline my-8">
      {price === 0 ? (
        <span className="text-5xl font-extrabold">Free</span>
      ) : (
        <>
          <span className="mr-2 text-5xl font-extrabold">${price}</span>
          <span className="text-gray-500 dark:text-gray-400">/month</span>
        </>
      )}
    </div>
    <ul className="mb-8 space-y-4 text-left">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center space-x-3">
          <Check className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" />
          <span>{feature}</span>
        </li>
      ))}
    </ul>
    <Link to="/login" className="mt-auto">
      <Button className={`w-full font-bold py-4 px-8 rounded-full text-lg transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg ${
        price === 0
          ? 'bg-white dark:bg-zinc-700 text-blue-800 dark:text-blue-500 hover:bg-teal-50 dark:hover:bg-zinc-600 border-2 border-blue-800 dark:border-blue-700'
          : 'bg-teal-500 hover:bg-teal-600 text-white'
      }`}>
        {price === 0 ? "Start Your Growth" : "Unlock Unlimited Growth"}
      </Button>
    </Link>
  </div>
);

const PricingPage = ({ darkMode }) => {
  const sharedFeatures = [
    "Access to Sage's AI model",
    "Personal growth insights",
    "Progress tracking",
    "24/7 availability",
  ];

  return (
    <div className={`flex flex-col min-h-screen ${darkMode ? 'bg-zinc-900 text-zinc-100' : 'bg-white text-gray-900'}`}>
      <main className="flex-grow py-8 px-4 mx-auto w-full max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold">Choose Your Path with Sage</h2>
          <p className="mb-5 font-light text-gray-600 sm:text-xl dark:text-gray-300">
            Embark on your journey to self-improvement and emotional intelligence with Sage, your AI guide. 
            Select the plan that best fits your desired pace of growth.
          </p>
        </div>
        <div className="space-y-8 lg:grid lg:grid-cols-2 sm:gap-6 xl:gap-10 lg:space-y-0">
          <PricingTier
            name="Sage Sapling"
            price={0}
            conversationFrequency="One conversation per week"
            features={[
              ...sharedFeatures,
              "Weekly check-ins for steady growth",
            ]}
            darkMode={darkMode}
          />
          <PricingTier
            name="Sage Evergreen"
            price={15}
            conversationFrequency="Unlimited conversations"
            features={[
              ...sharedFeatures,
              "Unlimited conversations for rapid progress",
              "Priority support",
            ]}
            isRecommended={true}
            darkMode={darkMode}
          />
        </div>
        <div className="mt-12 text-center">
          <h3 className="text-2xl font-bold mb-4">Why Choose Sage Evergreen?</h3>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="flex flex-col items-center">
              <Calendar className="text-teal-500 mb-2" size={32} />
              <h4 className="text-lg font-semibold mb-2">Accelerated Growth</h4>
              <p className="text-gray-600 dark:text-gray-300">Engage with Sage as often as you need, propelling your personal development forward at your own pace.</p>
            </div>
            <div className="flex flex-col items-center">
              <Zap className="text-teal-500 mb-2" size={32} />
              <h4 className="text-lg font-semibold mb-2">Continuous Support</h4>
              <p className="text-gray-600 dark:text-gray-300">Get instant guidance and insights whenever you need them, helping you navigate life's challenges in real-time.</p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default PricingPage;