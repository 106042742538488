import React from 'react';
import ReactMarkdown from 'react-markdown';
import { ScrollArea } from "./ui/ScrollArea";

const LoadingIndicator = ({ darkMode }) => (
  <div className={`p-4 rounded-2xl ${darkMode ? 'bg-zinc-700' : 'bg-white'} shadow-md inline-block animate-fadeIn`}>
    <div className="flex space-x-2">
      {[0, 1, 2].map((i) => (
        <div
          key={i}
          className={`w-2 h-2 rounded-full ${darkMode ? 'bg-zinc-400' : 'bg-zinc-300'} animate-pulse`}
          style={{ animationDelay: `${i * 0.15}s` }}
        />
      ))}
    </div>
  </div>
);

const MessageList = ({ messages, isLoading, darkMode, scrollAreaRef, messagesEndRef }) => (
  <ScrollArea
    className={`flex-grow ${darkMode ? "bg-zinc-900" : "bg-zinc-50"}`}
    ref={scrollAreaRef}
  >
    <div className="max-w-4xl mx-auto px-4 py-6 min-h-full flex flex-col">
      {messages.length > 0 && (
        <div className="space-y-4 mb-4">
          {messages.map((message, index) => (
            <div key={index} className={`${message.role === 'user' ? 'flex justify-end' : ''}`}>
              <div className={`inline-block p-3 rounded-lg max-w-[90%] ${
                message.role === 'user'
                  ? 'bg-teal-600 text-white'
                  : (darkMode ? 'bg-gray-600 text-white' : 'bg-gray-200 text-gray-800')
              } animate-fadeIn`}>
                <ReactMarkdown
                  className="text-left markdown-content"
                  components={{
                    p: ({ children, ...props }) => <p className="mb-2" {...props}>{children}</p>,
                    h1: ({ children, ...props }) => <h1 className="text-2xl font-bold mb-2" {...props}>{children}</h1>,
                    h2: ({ children, ...props }) => <h2 className="text-xl font-bold mb-2" {...props}>{children}</h2>,
                    h3: ({ children, ...props }) => <h3 className="text-lg font-bold mb-2" {...props}>{children}</h3>,
                    ul: ({ children, ...props }) => <ul className="list-disc pl-6 mb-2 space-y-1" {...props}>{children}</ul>,
                    ol: ({ children, ...props }) => <ol className="list-decimal pl-6 mb-2 space-y-1" {...props}>{children}</ol>,
                    li: ({ children, ...props }) => {
                      const hasNestedList = React.Children.toArray(children).some(
                        child => React.isValidElement(child) && (child.type === 'ul' || child.type === 'ol')
                      );
                      return (
                        <li className={hasNestedList ? "mb-1" : ""} {...props}>
                          {children}
                        </li>
                      );
                    },
                    a: ({ children, ...props }) => <a className="text-blue-500 hover:underline" {...props}>{children}</a>,
                    code: ({ children, inline, ...props }) =>
                      inline
                        ? <code className="bg-gray-200 dark:bg-gray-700 rounded px-1" {...props}>{children}</code>
                        : <code className="block bg-gray-200 dark:bg-gray-700 rounded p-2 my-2" {...props}>{children}</code>,
                    pre: ({ children, ...props }) => <pre className="bg-gray-200 dark:bg-gray-700 rounded p-2 my-2 overflow-x-auto" {...props}>{children}</pre>,
                  }}
                >
                  {message.content}
                </ReactMarkdown>
              </div>
            </div>
          ))}
        </div>
      )}
      {isLoading && (
        <div className="mt-4 flex justify-start">
          <LoadingIndicator darkMode={darkMode} />
        </div>
      )}
      <div ref={messagesEndRef} />
    </div>
  </ScrollArea>
);

export default MessageList;