import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "./ui/Dialog";
import { Button } from "./ui/Button";

const EndSessionModal = ({ showModal, onClose, onConfirm, darkMode }) => (
  <Dialog open={showModal} onOpenChange={onClose}>
    <DialogContent className={`${darkMode ? 'bg-zinc-800 text-zinc-100' : 'bg-white text-zinc-900'}`}>
      <DialogHeader>
        <DialogTitle className={`${darkMode ? 'text-zinc-100' : 'text-zinc-900'}`}>End Session</DialogTitle>
        <DialogDescription className={`${darkMode ? 'text-zinc-300' : 'text-zinc-600'}`}>
          Are you sure you want to end this session? All messages will be cleared and a summary of the session will be generated.
        </DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <Button 
          variant="outline" 
          onClick={onClose}
          className={`${darkMode ? 'bg-zinc-700 text-zinc-100 hover:bg-zinc-600' : 'bg-zinc-100 text-zinc-900 hover:bg-zinc-200'}`}
        >
          Cancel
        </Button>
        <Button 
          variant="destructive" 
          onClick={onConfirm}
          className="bg-teal-500 text-white hover:bg-teal-600"
        >
          End Session
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
);

export default EndSessionModal;