import React from 'react';
import { Link } from 'react-router-dom';
import { Lightbulb, Mountain, Target, TreePine } from 'lucide-react';
import { Button } from "./components/ui/Button";

const Feature = ({ icon: Icon, title, description }) => (
  <div className="flex flex-col items-center text-center p-6 bg-white dark:bg-zinc-800 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105">
    <div className="bg-teal-100 dark:bg-teal-900 rounded-full p-3 mb-4">
      <Icon className="h-8 w-8 text-teal-600 dark:text-teal-400" />
    </div>
    <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-white">{title}</h3>
    <p className="text-gray-600 dark:text-gray-300">{description}</p>
  </div>
);

const features = [
  {
    icon: Lightbulb,
    title: "Gain Deep Self-Awareness",
    description: "Explore your thoughts and emotions with Sage's insightful questions and reflective exercises."
  },
  {
    icon: Mountain,
    title: "Develop Emotional Resilience",
    description: "Learn practical strategies to navigate life's challenges and build inner strength."
  },
  {
    icon: Target,
    title: "Achieve Personalized Goals",
    description: "Create and pursue meaningful objectives with Sage's adaptive guidance and support."
  }
];

const HowItWorks = ({ steps }) => (
  <div className="grid md:grid-cols-4 gap-4 mb-16">
    {steps.map((step, index) => (
      <div key={index} className="flex flex-col items-center text-center">
        <div className="bg-teal-500 text-white rounded-full w-10 h-10 flex items-center justify-center mb-2">
          {index + 1}
        </div>
        <p className="text-gray-700 dark:text-gray-300">{step}</p>
      </div>
    ))}
  </div>
);

const howItWorksSteps = [
  "Start a conversation with Sage",
  "Explore your thoughts and feelings",
  "Receive personalized insights",
  "Take action and track progress"
];

const HomePage = ({ darkMode }) => {
  return (
    <div className={`flex flex-col min-h-screen ${darkMode ? 'bg-zinc-900 text-zinc-100' : 'bg-white text-gray-900'}`}>
      <main className="flex-grow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <header className="text-center mb-16">
            <div className="flex justify-center items-center mb-6">
              <TreePine className="h-12 w-12 text-teal-500 dark:text-teal-400 mr-4" />
              <h1 className="py-3 text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-teal-400 to-blue-500">
                Insight Path AI
              </h1>
            </div>
            <h2 className="text-4xl font-extrabold mb-4 text-gray-900 dark:text-white">
              Transform Your Life with 
              <span className="text-blue-500 dark:text-blue-500"> Sage</span>
              : AI-Powered Personal Development
            </h2>
            <p className="text-xl mb-8 max-w-3xl mx-auto text-gray-600 dark:text-gray-300">
              Embark on a transformative journey with Sage, your AI-powered guide to personal growth. 
              Insight Path AI combines advanced artificial intelligence with compassionate support, 
              helping you overcome challenges, discover your strengths, and achieve your goals. 
              Experience a new way of personal development tailored just for you.
            </p>
            <div className="space-y-4 sm:space-y-0 sm:space-x-4">
              <Link to="/chat">
                <Button className="my-2 bg-teal-500 hover:bg-teal-600 text-white font-bold py-4 px-8 rounded-full text-lg transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">
                  Start Your Free Session
                </Button>
              </Link>
              <Link to="/pricing">
                <Button className="my-2 bg-white dark:bg-zinc-800 text-teal-500 dark:text-teal-400 hover:bg-teal-50 dark:hover:bg-zinc-700 font-bold py-4 px-8 rounded-full text-lg transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg border-2 border-teal-500 dark:border-teal-400">
                  View Pricing
                </Button>
              </Link>
            </div>
            <p className="mt-4 text-sm text-gray-500 dark:text-gray-400">
              No credit card required. Experience a supportive conversation with Sage and discover your path to growth.
            </p>
          </header>

          <section className="grid md:grid-cols-3 gap-8 mb-16">
            {features.map((feature, index) => (
              <Feature key={index} {...feature} />
            ))}
          </section>

          <section className="text-center mb-16">
            <h3 className="text-3xl font-bold mb-4 text-gray-900 dark:text-white">How It Works</h3>
            <HowItWorks steps={howItWorksSteps} />
          </section>

          <section className="text-center mb-16">
            <h3 className="text-3xl font-bold mb-4 text-gray-900 dark:text-white">Ready to Transform Your Life with Sage?</h3>
            <p className="text-xl mb-8 text-gray-600 dark:text-gray-300">
              Join the community of individuals who have discovered the power of AI-assisted personal growth. 
              Let Sage guide you on your journey of emotional development and self-discovery.
            </p>
            <Link to="/chat">
              <Button className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-4 px-8 rounded-full text-lg transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">
                Start Talking With Sage
              </Button>
            </Link>
          </section>
        </div>
      </main>
    </div>
  );
};

export default HomePage;