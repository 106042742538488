import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { Button } from './components/ui/Button';
import BreathingCircle from './components/BreathingCircle';
import { Play, AlertCircle, Loader2 } from 'lucide-react';
import { Alert } from './components/ui/Alert';

const StartSessionButton = ({ onClick, isLoading, darkMode }) => {
  return (
    <Button
      onClick={onClick}
      disabled={isLoading}
      className={`w-full max-w-2xl mx-auto bg-teal-500 hover:bg-teal-600 text-white rounded-lg p-3 transition-colors duration-300 ease-in-out flex items-center justify-center ${
        darkMode ? 'hover:bg-teal-600' : 'hover:bg-teal-400'
      }`}
    >
      {isLoading ? (
        <>
          <Loader2 className="w-5 h-5 mr-2 animate-spin" />
          Starting...
        </>
      ) : (
        <>
          <Play className="h-5 w-5 mr-2" />
          Start Session with Sage
        </>
      )}
    </Button>
  );
};

const SessionInitiationPage = ({ darkMode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [canStartNewSession, setCanStartNewSession] = useState(null);
  const [isChecking, setIsChecking] = useState(true);
  const { user } = useAuth();
  const navigate = useNavigate();

  const checkCanStartNewSession = useCallback(async () => {
    if (!user) return;

    try {
      const token = await user.getIdToken();
      console.log(process.env.REACT_APP_API_URL);
      console.log(process.env.NODE_ENV);
      console.log(process.env);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/can-start-new-session`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (!response.ok) {
        throw new Error('Failed to check session availability');
      }

      const data = await response.json();
      setCanStartNewSession(data.canStartNewSession);
    } catch (err) {
      console.error('Error checking session availability:', err);
      setCanStartNewSession(false);
    } finally {
      setIsChecking(false);
    }
  }, [user]);

  useEffect(() => {
    checkCanStartNewSession();
  }, [checkCanStartNewSession]);

  const handleStartSession = async () => {
    if (!user) {
      alert("Please log in to start a session.");
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/chat/session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await user.getIdToken()}`
        },
        body: JSON.stringify({
          userId: user.uid,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to start session');
      }

      const data = await response.json();
      navigate(`/chat/${data.sessionId}`, { state: { initialMessage: data.aiResponse } });
    } catch (error) {
      console.error('Error starting session:', error);
      alert("Failed to start session. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubscribe = () => {
    navigate('/subscription');
  };

  if (isChecking) {
    return (
      <div className={`flex items-center justify-center h-screen ${darkMode ? 'bg-zinc-900 text-zinc-100' : 'bg-zinc-50 text-zinc-900'}`}>
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className={`flex flex-col items-center justify-center min-h-screen ${darkMode ? 'bg-zinc-900 text-zinc-100' : 'bg-zinc-50 text-zinc-900'}`}>
      <div className="mb-16">
        <BreathingCircle inTime={4} topTime={2} outTime={6} bottomTime={2} darkMode={darkMode} />
      </div>
      <p className="text-2xl mb-4">Take a moment to center yourself</p>
      {canStartNewSession ? (
        <>
          <p className="text-lg mb-4">When you're ready, you may start the session</p>
          <div className="w-full max-w-2xl px-4">
            <StartSessionButton onClick={handleStartSession} isLoading={isLoading} darkMode={darkMode} />
          </div>
        </>
      ) : (
        <div className="w-full max-w-2xl px-4">
          <Alert variant="warning" className="flex items-center mb-4">
            <AlertCircle className="h-4 w-4 mr-2" />
            <span>You may only start one session with Sage per week. Subscribe to Sage Evergreen to gain unlimited access.</span>
          </Alert>
          <Button
            onClick={handleSubscribe}
            className={`w-full bg-teal-500 hover:bg-teal-600 text-white rounded-lg p-3 transition-colors duration-300 ease-in-out flex items-center justify-center ${
              darkMode ? 'hover:bg-teal-600' : 'hover:bg-teal-400'
            }`}
          >
            Manage Subscription
          </Button>
        </div>
      )}
    </div>
  );
};

export default SessionInitiationPage;