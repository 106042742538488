import React, { useState, useEffect, useRef } from 'react';

const BreathingCircle = ({
  inTime = 4,
  topTime = 2,
  outTime = 6,
  bottomTime = 2,
  darkMode = false
}) => {
  const [scale, setScale] = useState(1);
  const [phase, setPhase] = useState('inhale');
  const [progress, setProgress] = useState(0);
  
  const animationRef = useRef(null);
  const lastTimeRef = useRef(0);
  const elapsedTimeRef = useRef(0);
  
  const cycleDuration = inTime + topTime + outTime + bottomTime;

  useEffect(() => {
    const animate = (currentTime) => {
      if (lastTimeRef.current !== 0) {
        const deltaTime = (currentTime - lastTimeRef.current) / 1000;
        elapsedTimeRef.current += deltaTime;

        const cycleTime = elapsedTimeRef.current % cycleDuration;

        if (cycleTime < inTime) {
          setPhase('inhale');
          setScale(1 + (0.5 * (cycleTime / inTime)));
          setProgress(cycleTime / inTime);
        } else if (cycleTime < inTime + topTime) {
          setPhase('holdTop');
          setScale(1.5);
          setProgress(1);
        } else if (cycleTime < inTime + topTime + outTime) {
          setPhase('exhale');
          const exhaleProgress = (cycleTime - inTime - topTime) / outTime;
          setScale(1.5 - (0.5 * exhaleProgress));
          setProgress(1 - exhaleProgress);
        } else {
          setPhase('holdBottom');
          setScale(1);
          setProgress(0);
        }
      }

      lastTimeRef.current = currentTime;
      animationRef.current = requestAnimationFrame(animate);
    };

    animationRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [inTime, topTime, outTime, bottomTime, cycleDuration]);

  const getColor = () => {
    const teal400 = [45, 212, 191];
    const blue500 = [59, 130, 246];
    
    const r = Math.round(teal400[0] + progress * (blue500[0] - teal400[0]));
    const g = Math.round(teal400[1] + progress * (blue500[1] - teal400[1]));
    const b = Math.round(teal400[2] + progress * (blue500[2] - teal400[2]));
    
    return `rgb(${r}, ${g}, ${b})`;
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="relative">
        <div
          className={`w-24 h-24 sm:w-32 sm:h-32 rounded-full transition-all duration-100 ease-linear flex items-center justify-center`}
          style={{
            transform: `scale(${scale})`,
            backgroundColor: getColor(),
            boxShadow: `0 0 10px ${getColor()}`,
          }}
        >
          <span className={`text-xs sm:text-sm font-semibold text-white`}>
            {phase === 'inhale' ? 'Breathe In' :
             phase === 'holdTop' ? 'Hold' :
             phase === 'exhale' ? 'Breathe Out' :
             'Rest'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default BreathingCircle;