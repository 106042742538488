import React from 'react';
import { Button } from "./ui/Button";

const SessionBanner = ({ darkMode, onEndSession, sessionEnded }) => (
  <div className={`p-2 ${darkMode ? 'bg-zinc-700' : 'bg-zinc-100'} flex justify-center`}>
    <div className="max-w-4xl w-full flex justify-between items-center">
      {sessionEnded ? <>
        <span className="text-sm font-medium">
          This session has ended and is only available for review.
        </span>
      </>
      : <>
        <span className="text-sm font-medium">
          Session in progress. You can end the session when you're finished.
        </span>
        <Button 
          onClick={onEndSession} 
          className={`
            ${darkMode 
              ? 'bg-zinc-800 hover:bg-zinc-600 text-zinc-300' 
              : 'bg-zinc-300 hover:bg-zinc-400 text-zinc-700'
            } 
            rounded-lg px-3 py-1 text-sm font-medium transition-colors duration-300 ease-in-out
          `}
          title="End Session"
        >
          End Session
        </Button>
      </>
      }
    </div>
  </div>
);

export default SessionBanner;