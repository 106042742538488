import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import Toolbar from './components/toolbar';
import Footer from './components/Footer';
import Routes from './Routes';

const AppContent = ({ darkMode, toggleDarkMode }) => {
  const location = useLocation();
  const showFooter = !location.pathname.startsWith('/chat');

  return (
    <div className={darkMode ? 'dark' : ''}>
      <Toolbar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      <Routes darkMode={darkMode} />
      {showFooter && <Footer darkMode={darkMode} />}
    </div>
  );
};

function App() {
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    if (savedMode !== null) {
      return JSON.parse(savedMode);
    }
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      if (localStorage.getItem('darkMode') === null) {
        setDarkMode(e.matches);
      }
    };
    mediaQuery.addListener(handleChange);
    return () => mediaQuery.removeListener(handleChange);
  }, []);

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(prevMode => !prevMode);
  };

  return (
    <AuthProvider>
      <Router>
        <AppContent darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      </Router>
    </AuthProvider>
  );
}

export default App;