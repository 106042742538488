import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent } from "./components/ui/Card";

const BlogListPage = ({ posts, darkMode }) => {
  return (
    <div className={`min-h-screen ${darkMode ? 'bg-zinc-900 text-zinc-100' : 'bg-white text-gray-900'}`}>
      <main className="container mx-auto px-4 py-8">
        <h1 className="text-center text-4xl font-bold mb-6">Blog Posts</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {posts.map((post) => (
            <Link key={post.id} to={`/blog/${post.id}`}>
              <Card 
                className={`${darkMode ? 'bg-zinc-800' : 'bg-white'} overflow-hidden transition-transform duration-200 ease-in-out transform hover:scale-105`}
              >
                <div className="h-48 overflow-hidden">
                  <img 
                    src={post.image} 
                    alt={post.title} 
                    className="w-full h-full object-cover"
                  />
                </div>
                <CardContent className="p-4">
                  <h3 className="text-2xl font-semibold my-2">
                    {post.title}
                  </h3>
                  <p className={`${darkMode ? 'text-zinc-400' : 'text-gray-600'} mb-2 text-sm`}>{post.date}</p>
                  <p className={`${darkMode ? 'text-zinc-300' : 'text-gray-800'}`}>{post.excerpt}</p>
                </CardContent>
              </Card>
            </Link>
          ))}
        </div>
      </main>
    </div>
  );
};

export default BlogListPage;