import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import HomePage from './HomePage';
import LoginPage from './LoginPage';
import ChatInterface from './ChatInterface';
import SessionInitiationPage from './SessionInitiationPage';
import PricingPage from './PricingPage';
import DashboardPage from './DashboardPage';
import ProfilePage from './ProfilePage';
import PrivateRoute from './PrivateRoute';
import SubscriptionManagementPage from './SubscriptionManagementPage';
import BlogListPage from './BlogListPage';
import BlogPostPage from './BlogPostPage';
import { blogPosts } from './data/blogPosts';
import NotFoundPage from './NotFoundPage';

function AppRoutes({ darkMode }) {
  const { completePasswordlessSignIn, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const checkEmailLink = async () => {
      if (window.location.href.includes('finishSignIn')) {
        try {
          await completePasswordlessSignIn();
          navigate('/profile');
        } catch (error) {
          console.error('Error signing in with email link', error);
        }
      }
    };
    checkEmailLink();
  }, [completePasswordlessSignIn, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route path="/" element={<HomePage darkMode={darkMode} />} />
      <Route path="/login" element={<LoginPage darkMode={darkMode} />} />
      <Route path="/pricing" element={<PricingPage darkMode={darkMode} />} />
      <Route path="/profile" element={<ProfilePage darkMode={darkMode} />} />
      <Route path="/blog" element={<BlogListPage posts={blogPosts} darkMode={darkMode} />} />
      <Route path="/blog/:id" element={<BlogPostPage posts={blogPosts} darkMode={darkMode} />} />
      <Route element={<PrivateRoute />}>
        <Route path="/chat" element={<SessionInitiationPage darkMode={darkMode} />} />
        <Route path="/chat/:sessionId" element={<ChatInterface darkMode={darkMode} />} />
        <Route path="/dashboard" element={<DashboardPage darkMode={darkMode} />} />
        <Route path="/subscription" element={<SubscriptionManagementPage darkMode={darkMode}/>} />
      </Route>
      <Route path="*" element={<NotFoundPage darkMode={darkMode} />} />
    </Routes>
  );
}

export default AppRoutes;