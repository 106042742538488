import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TreePine, Menu } from 'lucide-react';
import { Button } from "./ui/Button";
import DropdownMenu from "./DropdownMenu";
import { useAuth } from '../AuthContext';

const Toolbar = ({ darkMode, toggleDarkMode }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user } = useAuth();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <header className={`flex justify-between items-center p-4 ${darkMode ? 'bg-zinc-800' : 'bg-white'} shadow-sm transition-colors duration-300 ease-in-out`}>
      <Link to="/" className="flex items-center">
        <TreePine className="h-8 w-8 text-teal-500 mr-2" />
        <span className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-teal-400 to-blue-500">Insight Path AI</span>
      </Link>
      <div className="flex items-center">
        <nav className="hidden md:flex space-x-4 mr-4">
          {!user && <Link to="/" className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white">Home</Link>}
          {user && <Link to="/dashboard" className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white">Dashboard</Link>}
          <Link to="/blog" className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white">Blog</Link>
        </nav>
        {!user && (
          <Link to="/login" className="mr-4">
            <Button className="hidden md:inline-flex bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded-full text-sm transition duration-300 ease-in-out">
              Log In / Sign Up
            </Button>
          </Link>
        )}
        <Button
          variant="ghost"
          size="icon"
          onClick={toggleMenu}
          className="dark:text-gray-200 text-gray-700 hover:bg-gray-100 dark:hover:bg-zinc-700 hover:text-gray-800 dark:hover:text-gray-200"
        >
          <Menu className="h-6 w-6" />
        </Button>
      </div>
      <DropdownMenu
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        darkMode={darkMode}
        toggleDarkMode={toggleDarkMode}
        isLoggedIn={!!user}
      />
    </header>
  );
};

export default Toolbar;